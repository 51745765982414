<template>
  <div>
    <div v-show="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Artemis Trading Config</h3>
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-6">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="DB Connection URL" rules="required"
                  tag="div">
                  <label>Database Connection URL</label>
                  <input type="text" v-model="form.DB_CONNECTION" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="DB Username" rules="required"
                  tag="div">
                  <label>Database Username</label>
                  <input type="text" v-model="form.DB_USERNAME" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="DB Password" rules="required"
                  tag="div">
                  <label>Database Password</label>
                  <div class="flex items-center">
                    <input :type="`${isHiddenPassword ? 'password' : 'text'}`" v-model="form.DB_PASSWORD"
                      class="form-control mr-4">
                    <button type="button" @click="isHiddenPassword = !isHiddenPassword">
                      <i :class="`fa ${isHiddenPassword ? 'fa-eye' : 'fa-eye-slash'}`" />
                    </button>
                  </div>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="DB Minimum Idle" rules="required"
                  tag="div">
                  <label>Database Minimum Idle</label>
                  <input type="text" v-model="form.DB_MIN_IDLE" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="DB Pool size" rules="required"
                  tag="div">
                  <label>Database Pool Size</label>
                  <input type="text" v-model="form.DB_POOL_SIZE" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="DB Timestamp" rules="required"
                  tag="div">
                  <label>Database Timestamp</label>
                  <input type="text" v-model="form.DB_TIMESTAMP" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Base market" rules="required"
                  tag="div">
                  <label>Base Market</label>
                  <select v-model="form.BASE_MARKET" class="form-control">
                    <option value="btc">BTC</option>
                    <option value="ltc">LTC</option>
                    <option value="dog">DOGE</option>
                    <option value="erx">EURx</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Flex engine" rules="required"
                  tag="div">
                  <label>Flex Engine</label>
                  <select v-model="form.FLEX_ENGINE" class="form-control">
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Auto trader" rules="required"
                  tag="div">
                  <label>Auto Trader</label>
                  <select v-model="form.AUTO_TRADER" class="form-control">
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Auto trading from" rules="required"
                  tag="div">
                  <label>Auto Trading From</label>
                  <input type="text" v-model="form.AUTO_TRADER_FROM" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Auto trading to" rules="required"
                  tag="div">
                  <label>Auto Trading To</label>
                  <input type="text" v-model="form.AUTO_TRADER_TO" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Minimum trading amount"
                  rules="required" tag="div">
                  <label>Minimum Trading Amount</label>
                  <input type="text" v-model="form.MIN_AMT" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Minimum total trade" rules="required"
                  tag="div">
                  <label>Minimum Total Trade</label>
                  <input type="text" v-model="form.MIN_TOTAL_TRADE" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Full host name" rules="required"
                  tag="div">
                  <label>Full Host Name</label>
                  <input type="text" v-model="form.FULL_HOST_NAME" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Short url" rules="required" tag="div">
                  <label>Short URL</label>
                  <input type="text" v-model="form.SHORT_URL" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="AES key" rules="required" tag="div">
                  <label>AES Key</label>
                  <div class="flex items-center">
                    <input :type="`${isHiddenKey ? 'password' : 'text'}`" v-model="form.AES_KEY"
                      class="form-control mr-4">
                    <button type="button" @click="isHiddenKey = !isHiddenKey">
                      <i :class="`fa ${isHiddenKey ? 'fa-eye' : 'fa-eye-slash'}`" />
                    </button>
                  </div>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="ExChange id height" rules="required"
                  tag="div">
                  <label>ExChange ID Height</label>
                  <input type="text" v-model="form.EXCHANGE_ID_HEIGHT" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" class="form-group" name="Verbose" rules="required" tag="div">
                  <label>Verbose</label>
                  <select v-model="form.VERBOSE" class="form-control">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <div class="text-right">
                  <button :disabled="isProcessing" class="btn btn-primary btn-flat" type="submit">
                    Update
                    <span v-if="isProcessing">
                      <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";

export default {
  name: "TradingConfig",
  data() {
    return {
      apiBaseUrl: "https://artemis.cointopay.com/trading",
      form: {
        DB_CONNECTION: "",
        DB_USERNAME: "",
        DB_PASSWORD: "",
        DB_MIN_IDLE: "",
        DB_POOL_SIZE: "",
        DB_TIMESTAMP: "",
        BASE_MARKET: "",
        FLEX_ENGINE: "",
        AUTO_TRADER: "",
        AUTO_TRADER_FROM: "",
        AUTO_TRADER_TO: "",
        MIN_AMT: "",
        MIN_TOTAL_TRADE: "",
        FULL_HOST_NAME: "",
        SHORT_URL: "",
        AES_KEY: "",
        EXCHANGE_ID_HEIGHT: "",
        VERBOSE: "",
      },
      isProcessing: false,
      isHiddenPassword: true,
      isHiddenKey: true,
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
    ]),
  },
  methods: {
    fetchConfig(isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      this.$http.get(`${this.apiBaseUrl}/config`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.form = response.data.data;
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        if (error.status === 404) {
          this.$toast.fire("", "Server is offline", "error");
        } else {
          this.$toast.fire("", error.data.message, "error");
        }
      });
    },
    onSubmit() {
      const payload = JSON.parse(JSON.stringify(this.form));
      this.isProcessing = true;
      this.$http.post(`${this.apiBaseUrl}/config`, this.qs.stringify(payload), {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.isProcessing = false;
        this.$toast.fire("", response.data.reason, "success");
        setTimeout(() => {
          this.fetchConfig(false);
        }, 3000);
      }).catch(error => {
        this.isProcessing = false;
        this.$toast.fire("", error.data.reason, "error");
      });
    },
  },
  beforeMount() {
    this.fetchConfig();
  },
};
</script>
